import _defineProperty from "/builds/u5yGSXwH/2/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _pdpInstructions, _dialogInstructions;

import { CertificateType } from '@src/types/graphql-types';
import { messages as pdpMessages } from '@src/routes/ProductDetail/messages';
import { messages as utilsMessages } from '@utils/messages';
var sigmaProducts = {
  heading: utilsMessages.SIGMA_PRODUCTS,
  listItems: [pdpMessages.FIND_LOT_NUMBER_SIGMA_1]
};
var aldrichProducts = {
  heading: utilsMessages.ALDRICH_PRODUCTS,
  listItems: [pdpMessages.FIND_LOT_NUMBER_ALDRICH_1, pdpMessages.FIND_LOT_NUMBER_ALDRICH_2, pdpMessages.FIND_LOT_NUMBER_ALDRICH_3]
};
var milliporeProducts = {
  heading: pdpMessages.FIND_LOT_NUMBER_MILLIPORE,
  listItems: [pdpMessages.FIND_LOT_NUMBER_MILLIPORE_1, pdpMessages.FIND_LOT_NUMBER_MILLIPORE_2]
};
export var pdpInstructions = (_pdpInstructions = {}, _defineProperty(_pdpInstructions, CertificateType.Coa, [sigmaProducts, aldrichProducts, milliporeProducts]), _defineProperty(_pdpInstructions, CertificateType.Coo, [sigmaProducts, aldrichProducts, milliporeProducts]), _defineProperty(_pdpInstructions, CertificateType.Coq, [sigmaProducts, aldrichProducts, milliporeProducts]), _pdpInstructions);
var dialogOverview = {
  bodyCopy: {
    id: 'HOW_TO_ENTER_COO_COA_INSTRUCTIONS',
    defaultMessage: "Lot and Batch Numbers can be found on a product's label following the words 'Lot' or 'Batch'."
  }
};
var dialogAldrichProducts = {
  heading: utilsMessages.ALDRICH_PRODUCTS,
  listItems: [utilsMessages.ENTER_WITHOUT_FIRST_TWO_LETTERS, utilsMessages.ENTER_WITHOUT_NUMERIC_FILLING_CODE, utilsMessages.ENTER_WITHOUT_ALPHA_NUMERIC_FILLING_CODE]
};
var dialogSigmaProducts = {
  heading: utilsMessages.SIGMA_PRODUCTS,
  listItems: [utilsMessages.SIGMA_PRODUCTS_INSTRUCTIONS]
};
var dialogMilliporeProducts = {
  heading: utilsMessages.MILLIPORE_PRODUCTS,
  bodyCopy: utilsMessages.MILLIPORE_PRODUCTS_INSTRUCTIONS,
  link: true
};
export var dialogInstructions = (_dialogInstructions = {}, _defineProperty(_dialogInstructions, CertificateType.Coa, [dialogOverview, dialogAldrichProducts]), _defineProperty(_dialogInstructions, CertificateType.Coo, [dialogOverview, dialogSigmaProducts, dialogAldrichProducts]), _defineProperty(_dialogInstructions, CertificateType.Coq, [dialogOverview, dialogMilliporeProducts]), _dialogInstructions);